
.instructive {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 ;
}

.mainMenu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  z-index: 1;
}

.mainMenu .menuOption img {
  width: 70px;
  margin: 10px;
  animation: pulso 2s infinite;
  -webkit-box-shadow: 0px 0px 32px 3px rgba(0,0,0,0.23);
  -moz-box-shadow: 0px 0px 32px 3px rgba(0,0,0,0.23);
  box-shadow: 0px 0px 32px 3px rgba(0,0,0,0.23);
}

@keyframes pulso {
  from { transform: none;}
  50% {transform: scale(1.2);}
  to {transform: none;}
}

