.header {
  background-image: url('../assets/components/header_full.jpg');
  height: 60px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  background-size: cover;
  text-align: center;
}

.back {
  width: 50px;
  margin: 22px 0;
  animation: pulso 2s infinite;
}

@keyframes pulso {
  from {transform: none;}
  50% {transform: scale(1.08);}
  to {transform: none;}
}

.brandLogo {
  text-align: center;
  margin: 30px 20px 0 20px;
}

.brandLogo img {
  height: auto;
  width: 100%;
}

.slide p {
  font-size: 15px;
  color: #439555;
  margin: 5px;
  ;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 230px;
  width: 100%;
}

.footer p {
  position: relative;
  text-align: center;
  bottom: -200px;
  color: #BCC585;
}

.introModal {
  background: #FCF5EF;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  animation: 1s;
}

.hide {
  visibility: hidden;
}

.introModal .introContent {
  margin: 50px;
}

.decorUp img {
  height: 200px;
}

.familyImage img {
  height: 200px;
}

.introModal .novenaImage {
  margin-top: 50px;
}

.introModal .brandLogo {
  margin: 0 0 20px 0;
}

@media screen and (min-width: 800px) {
  .introModal .novenaImage img {
    height: 100px;
  }
  .brandLogo img {
    height: auto;
    width: 30%;
  }
  .mainMenu .menuOption img {
    width: 80px;
  }
}