@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
* {
  font-family: 'Patrick Hand', cursive;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(248, 245, 245);
}
.steps .back {
  height: 30px;
  width: 80px;
}

.steps .video iframe {
  margin: 30px 0;
}
.header {
  background-image: url(/static/media/header_full.0276a683.jpg);
  height: 60px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  background-size: cover;
  text-align: center;
}

.back {
  width: 50px;
  margin: 22px 0;
  -webkit-animation: pulso 2s infinite;
          animation: pulso 2s infinite;
}

@-webkit-keyframes pulso {
  from {-webkit-transform: none;transform: none;}
  50% {-webkit-transform: scale(1.08);transform: scale(1.08);}
  to {-webkit-transform: none;transform: none;}
}

@keyframes pulso {
  from {-webkit-transform: none;transform: none;}
  50% {-webkit-transform: scale(1.08);transform: scale(1.08);}
  to {-webkit-transform: none;transform: none;}
}

.brandLogo {
  text-align: center;
  margin: 30px 20px 0 20px;
}

.brandLogo img {
  height: auto;
  width: 100%;
}

.slide p {
  font-size: 15px;
  color: #439555;
  margin: 5px;
  ;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 230px;
  width: 100%;
}

.footer p {
  position: relative;
  text-align: center;
  bottom: -200px;
  color: #BCC585;
}

.introModal {
  background: #FCF5EF;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  -webkit-animation: 1s;
          animation: 1s;
}

.hide {
  visibility: hidden;
}

.introModal .introContent {
  margin: 50px;
}

.decorUp img {
  height: 200px;
}

.familyImage img {
  height: 200px;
}

.introModal .novenaImage {
  margin-top: 50px;
}

.introModal .brandLogo {
  margin: 0 0 20px 0;
}

@media screen and (min-width: 800px) {
  .introModal .novenaImage img {
    height: 100px;
  }
  .brandLogo img {
    height: auto;
    width: 30%;
  }
  .mainMenu .menuOption img {
    width: 80px;
  }
}
.calendar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 50px 0;
  z-index: 1;
}

.calendar img {
  width: 71px;
  margin-top: 5px;
  border-radius: 10px;
}

.steps {
  margin: 30px;
  text-align: center;
}

.steps .dayImg img {
  height: 75px;
  width: 75px;
  box-shadow: 0px 0px 32px 3px rgba(0,0,0,0.23);
}

.steps h2 {
  font-size: 20px;
}

.steps .stepText {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  height: 270px;
  overflow: auto;
  text-align: left;
  font-size: 20px;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
}

.stepText p {
  margin: 10px 0;
  line-height: 23px;
}

.stepText .gozos {
  line-height: normal;
  letter-spacing: normal;
  font-size: 18px;
  margin: 0;
}

.steps small {
  color: brown;
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
}

.steps .sings {
  position: relative;
  width: 250px;
  text-align: center;
  margin: 20px auto;
  z-index: 1;
}

.steps .sings a {
  display: flex;
  justify-content: center;
  background-color: brown;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  margin: 10px 0;
  font-size: 17px;
  border-radius: 20px;
}

#green {
  background-color: #439555;
}

#clearGreen {
  background-color: #BCC585;
  color: #439555;
}

#red {
  background-color: #CA3029;
}

#orange {
  background-color: #E9AE3D;
}

#brown {
  background-color: #C3893E;
}

.buttons {
  text-align: center;
  position: relative;
  bottom: -50px;
}

.buttonBefore, .buttonAfter{
  height: 50px;
  text-align: center;
  -webkit-animation: pulso 2s infinite;
          animation: pulso 2s infinite;
  margin: 0 10px;
}

@-webkit-keyframes pulso {
  from {-webkit-transform: none;transform: none;}
  50% {-webkit-transform: scale(1.2);transform: scale(1.2)}
  to {-webkit-transform: none;transform: none;}
}

@keyframes pulso {
  from {-webkit-transform: none;transform: none;}
  50% {-webkit-transform: scale(1.2);transform: scale(1.2)}
  to {-webkit-transform: none;transform: none;}
}


.dayImg img {
  width: 50px;
  text-align: center;
}

.instructive {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 ;
}

.mainMenu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  z-index: 1;
}

.mainMenu .menuOption img {
  width: 70px;
  margin: 10px;
  -webkit-animation: pulso 2s infinite;
          animation: pulso 2s infinite;
  box-shadow: 0px 0px 32px 3px rgba(0,0,0,0.23);
}

@-webkit-keyframes pulso {
  from { -webkit-transform: none; transform: none;}
  50% {-webkit-transform: scale(1.2);transform: scale(1.2);}
  to {-webkit-transform: none;transform: none;}
}

@keyframes pulso {
  from { -webkit-transform: none; transform: none;}
  50% {-webkit-transform: scale(1.2);transform: scale(1.2);}
  to {-webkit-transform: none;transform: none;}
}


