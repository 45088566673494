@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

* {
  font-family: 'Patrick Hand', cursive;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(248, 245, 245);
}